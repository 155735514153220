// @include form-control-placeholder($default-color, .8);

// .form-control {
//     background-color: $transparent-bg;
//     border: 1px solid $light-gray;
//     border-radius: $btn-round-radius;
//     color: $black-color;
//     height: auto;
//     line-height: normal;
//     font-size: $font-size-small;
//     @include transition-input-focus-color();
//     @include box-shadow(none);

//     .has-success &{
//         border-color: $light-gray;
//     }

//     &:focus{
//         border: 1px solid $primary-color;
//         @include box-shadow(none);
//         outline: 0 !important;
//         color: $black-color;

//         & + .input-group-text,
//         & ~ .input-group-text{
//             border: 1px solid $primary-color;
//             border-left: none;
//             background-color: $transparent-bg;
//         }
//     }

//     .has-success &,
//     .has-error &,
//     .has-success &:focus,
//     .has-error &:focus{
//         @include box-shadow(none);
//     }

//     .has-success &:focus{
//         border-color: lighten($success-color, 5%);
//     }

//     .has-danger &,
//     .has-success &{
//         &.form-control-success,
//         &.form-control-danger{
//             background-image: none;
//         }
//     }

//     .has-danger &{
//         border-color: lighten($danger-color, 30%);
//         color: $danger-color;
//         background-color: rgba(222,222,222, .1);

//         &:focus{
//             background-color: $white-color;
//         }
//     }

//     & + .form-control-feedback{
//         border-radius: $border-radius-large;
//         font-size: $font-size-base;
//         margin-top: -7px;
//         position: absolute;
//         right: 10px;
//         top: 50%;
//         vertical-align: middle;
//     }

//     .open &{
//         border-radius: $border-radius-large $border-radius-large 0 0;
//         border-bottom-color: transparent;
//     }

//     // & + .input-group-text{
//     //     background-color: $white-bg;
//     // }
// }

// .has-success,
// .has-danger{
//     &:after{
//         font-family: 'Nucleo Outline';
//         content: "\ea22";
//         display: inline-block;
//         position: absolute;
//         right: 20px;
//         top: 12px;
//         color: $success-color;
//         font-size: 11px;
//     }

//     &.input-lg{
//         &:after{
//             font-size: 13px;
//             top: 13px;
//         }
//     }
// }

// .has-danger{
//     &:after{
//         content: "\ea53";
//         color: $danger-color;
//     }
// }

// @include input-lg-padding($padding-large-vertical, $padding-input-horizontal);
// @include input-base-padding($padding-input-vertical, $padding-input-horizontal);

// .form-group.no-border,
// .input-group.no-border{
//     .form-control,
//     .form-control + .input-group-text{
//         background-color: $opacity-gray-3;
//         border: medium none;
//         &:focus,
//         &:active,
//         &:active{
//             border: medium none;
//             background-color: $opacity-gray-5;
//         }
//     }

//     .form-control{
//         &:focus{
//             & + .input-group-text{
//                 background-color: $opacity-gray-5;
//             }
//         }
//     }
//     .input-group-prepend{
//       .input-group-text{
//           background-color: $opacity-gray-3;
//           border: none;
//           border-left: transparent !important;
//       }
//     }

//     &.input-group-focus{
//         &.no-border{
//             .input-group-text{
//                 background-color: $opacity-gray-5;
//             }
//         }
//     }
// }

// .has-error{
//     .form-control-feedback, .control-label{
//         color: $danger-color;
//     }
// }
// .has-success{
//     .form-control-feedback, .control-label{
//         color: $success-color;
//     }
// }

// .input-group-text {
//     background-color: $white-bg;
//     border: 1px solid $light-gray;
//     border-radius: $btn-round-radius;
//     color: $dark-background;

//     @include transition-input-focus-color();

//     .input-group-focus &{
//       background-color: $white-bg;
//       border-color: $primary-color;
//     }

//     .has-success &,
//     .has-danger &{
//         background-color: $white-color;
//     }
//     .has-danger .form-control:focus + &{
//         color: $danger-color;
//     }
//     .has-success .form-control:focus + &{
//         color: $success-color;
//     }

//     & + .form-control,
//     & ~ .form-control{
//         @include input-size($padding-base-vertical - 1, $padding-base-horizontal);
//         padding-left: 18px;
//     }

//     i{
//         width: 17px;
//     }
// }

// .input-group,
// .form-group{
//     margin-bottom: 10px;
//     position: relative;
// }
// .input-group[disabled]{
//     .input-group-text{
//         background-color: $light-gray;
//     }
// }

// .input-group,
// .form-group{
//   .input-group-prepend{
//     margin-right: 0;

//     .input-group-text {
//       border-left: 1px solid #E3E3E3;
//     }
//   }
//   .input-group-append .input-group-text {
//     border-right: 1px solid #E3E3E3;
//     padding: 10px 18px 10px 0;
//   }

//   &.input-group-focus {
//     .input-group-prepend .input-group-text {
//       border-left: 1px solid #f96332;
//     }
//     .input-group-append .input-group-text {
//       border-right: 1px solid #f96332;
//     }
//     &.no-border{
//       .input-group-prepend .input-group-text{
//         border-left: transparent;
//       }
//     }
//   }
// }

// .input-group .form-control:first-child,
// .input-group-text:first-child,
// .input-group-btn:first-child > .dropdown-toggle,
// .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
//     border-right: 0 none;
// }
// .input-group .form-control:last-child,
// .input-group-text:last-child,
// .input-group-btn:last-child > .dropdown-toggle,
// .input-group-btn:first-child > .btn:not(:first-child) {
//     border-left: 0 none;
// }
// .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
//     background-color: $light-gray;
//     color: $default-color;
//     cursor: not-allowed;
// }

// .input-group-btn .btn{
//     border-width: $border-thin;
//     padding: $padding-btn-vertical  $padding-base-horizontal;
// }
// .input-group-btn .btn-default:not(.btn-fill){
//     border-color: $medium-gray;
// }

// .input-group-btn:last-child > .btn{
//     margin-left: 0;
// }
// textarea.form-control{
//     max-width: 100%;
//     max-height: 80px;
//     padding: 10px 10px 0 0;
//     resize: none;
//     border: none;
//     border: 1px solid $light-gray;
//     border-radius: 8px;
//     line-height: 2;

//     &:focus,
//     &:active{
//         border-left: none;
//         border-top: none;
//         border-right: none;
//     }
// }

// .has-success,
// .has-danger{

//     &.form-group .form-control,
//     &.form-group.no-border .form-control{
//         padding-right: $padding-input-horizontal + 21;
//     }
// }

// .form.form-newsletter .form-group{
//     float: left;
//     width: 78%;
//     margin-right: 2%;
//     margin-top: 9px;
// }

// .input-group .input-group-btn{
//     padding: 0 12px;
// }

// // Input files - hide actual input - requires specific markup in the sample.
// .form-group input[type=file] {
//   opacity: 0;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 100;
// }
.form-select {
	background-image: url("https://cdcloans.com/wp-content/uploads/2017/10/downArrow1.png");
	background-repeat: no-repeat;
	background-position: 99% center;
	background-size: 26px;
}

.form-select option {
	background-color: #383838;
}

::-webkit-calendar-picker-indicator {
	filter: invert(1);
}