.radio_btn_group {
  .radio_button {
    border: 1px solid #007db3;
    background-color: transparent;
    color: #fff;
    margin-right: 16px;
    border-radius: 5px;
    padding: 4px 16px 4px 8px;

    &:hover,
    &.active {
      background-color: #007db3;
    }

    img {
      width: 52px;
      margin-right: 16px;
    }
  }
}

.form_dropdown_holder {
  .file_holder {
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #fff;
    border-radius: 5px;
    margin-bottom: 8px;
    flex-direction: column;

    img {
      width: 72px;
    }
  }

  .file_holder_below_text {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 8px;
  }
}

.child_table_main .row {
  display: grid !important;
}

.child-table {
  width: auto;
  overflow-x: scroll;
}

tr th {
  text-align: center;
}

tr td:last-child,
tr td:nth-last-child(2) {
  text-align: center;
}

table {
  // scroll-behavior: smooth;
}

table thead th {
  position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
  position: sticky;
  top: 0;
  z-index: 1; // any positive value, layer order is global
  background: #fff; // any bg-color to overlap
}

table tr {
  // overflow-x: scroll;
  // overflow-y: scroll;
  // white-space: nowrap;
  // position: sticky;
}

::-webkit-scrollbar {
  // -webkit-appearance: none;
  width: 8px;
  height: 8px;
  // scrollbar-width: '';
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(243, 236, 236, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

// #scrollarea-content {
//   min-height: 101%;
// }
// .visible-scrollbar,
// .invisible-scrollbar,
// .mostly-customized-scrollbar {
//   display: block;
//   width: 10em;
//   overflow: auto;
//   height: 2em;
// }

// .invisible-scrollbar::-webkit-scrollbar {
//   display: none;
// }

// /* Demonstrate a "mostly customized" scrollbar
//  * (won't be visible otherwise if width/height is specified) */
// .mostly-customized-scrollbar::-webkit-scrollbar {
//   width: 5px;
//   height: 8px;
//   // background-color: #aaa; /* or add it to the track */
// }

// /* Add a thumb */
// .mostly-customized-scrollbar::-webkit-scrollbar-thumb {
//   background: #000;
// }

@media (min-width: 576px) {
  .full-sc .modal-dialog {
    max-width: 95% !important;
    margin: 1.75rem auto;
  }

  .full-md .modal-dialog {
    max-width: 70% !important;
    margin: 1.75rem auto;
  }
}

#tap_list_mod_table th,
#tap_list_mod_table td {
  white-space: nowrap;
  text-align: center;
}

#engage_list_page {
  padding: 10px 28px;
}
.error {
  color: #ff0000;
}
.shot td
// .table-responsive th
{
  padding-top: 2 !important;
  padding-bottom: 2 !important;
}
