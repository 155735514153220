.footer {
    padding: 24px 0;
    position: absolute;
    bottom: 0;
    width: 100%;

    &.footer-default {
        background-color: #000;
        color: #fff;
    }

    nav {
        display: inline-block;
        // float: left;
    }

    ul {
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            a {
                color: #fff;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .copyright {
        font-size: $font-size-small;
    }

    &:after {
        display: table;
        clear: both;
        content: " ";
    }
}