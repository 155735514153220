body#log-form {
  background-color: #383838;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #2b3e51;
}

#log-form h2 {
  font-weight: 300;
  text-align: center;
  color: #fff;
}

#log-form p {
  position: relative;
}

a,
a:link,
a:visited,
a:active {
  color: #3ca9e2;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
a:focus,
a:hover,
a:link:focus,
a:link:hover,
a:visited:focus,
a:visited:hover,
a:active:focus,
a:active:hover {
  color: #329dd5;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#login-form-wrap {
  background-color: #000;
  width: 35%;
  margin: 30px auto;
  text-align: center;
  padding: 20px 0 0 0;
  border-radius: 4px;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
}

#login-form {
  padding: 0 60px;
}

#log-form input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
}

#log-form input[type="text"],
#log-form input[type="email"] {
  width: 100%;
  padding: 0 0 0 10px;
  margin: 0;
  color: #8a8b8e;
  border: 1px solid #c2c0ca;
  font-style: normal;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  background: none;
}
#log-form input[type="text"]:focus,
#log-form input[type="email"]:focus {
  border-color: #3ca9e2;
}
#log-form input[type="text"]:focus:invalid,
#log-form input[type="email"]:focus:invalid {
  color: #cc1e2b;
  border-color: #cc1e2b;
}
#log-form input[type="text"]:valid ~ .validation,
#log-form input[type="email"]:valid ~ .validation {
  display: block;
  border-color: #0c0;
}
#log-form input[type="text"]:valid ~ .validation span,
#log-form input[type="email"]:valid ~ .validation span {
  background: #0c0;
  position: absolute;
  border-radius: 6px;
}
#log-form input[type="text"]:valid ~ .validation span:first-child,
#log-form input[type="email"]:valid ~ .validation span:first-child {
  top: 30px;
  left: 14px;
  width: 20px;
  height: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#log-form input[type="text"]:valid ~ .validation span:last-child,
#log-form input[type="email"]:valid ~ .validation span:last-child {
  top: 35px;
  left: 8px;
  width: 11px;
  height: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#log-form .validation {
  display: none;
  position: absolute;
  content: " ";
  height: 60px;
  width: 30px;
  right: 15px;
  top: 0px;
}

#log-form input[type="submit"] {
  border: none;
  display: block;
  background-color: #3ca9e2;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 18px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}
#log-form input[type="submit"]:hover {
  background-color: #329dd5;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#create-account-wrap {
  background-color: #4d4d4d;
  color: #8a8b8e;
  font-size: 14px;
  width: 100%;
  padding: 10px 0;
  border-radius: 0 0 4px 4px;
}

/* ===============================
    My Stylesheet
=========================== */

.top-header {
  background-color: #000012 !important;
}
.top-header .form-inline .form-select {
  color: #3ca9e2 !important;
  background-color: transparent !important;
  border: 1px solid #3ca9e2;
  height: 40px;
}
.top-header .form-inline .btn-primary {
  color: #3ca9e2;
  background-color: transparent !important;
  border: 1px solid #3ca9e2;
  height: 40px;
}
.top-header .top-right-icon .btn {
  color: #3ca9e2;
}
#engage_list_page .filter_form .btn {
  border: 1px solid #845efc;
}
#engage_list_page .table-dark th {
  background-color: #000012 !important;
}
/* end Header */

.welcome_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/welcome_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
}
.welcome_page::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: #000012;
  opacity: 0.6;
}
.welcome_page #log-form {
  width: 100%;
  position: relative;
}
.welcome_page #login-form-wrap {
  background: none;
}
.welcome_page #login-form-wrap .logo {
  background: #000012;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  margin: 0 auto 2rem;
}
.welcome_page h2 {
  color: #eeeb11 !important;
  /* color: #9b1fe9 !important; */
  font-weight: 400 !important;
}
.welcome_page #login-form-wrap #create-account-wrap {
  background: none;
}
.welcome_page #login-form-wrap #create-account-wrap .btn {
  color: #3ca9e2;
  background-color: transparent;
  border: 1px solid #3ca9e2;
}
/* PAGE:: end Welcome */

#engagement_details_page .clent-box-row .client-name {
  background-color: #000012;
}
#engagement_details_page .accordion-flush {
  border: none;
}
#engagement_details_page .accordion .accordion-item + .accordion-item {
  margin-top: 1rem;
}
#engagement_details_page .accordion .accordion-item {
  border: 1px solid #3ca9e2;
}
#engagement_details_page .accordion-flush .accordion-item .accordion-button {
  color: #ffffff;
  border-color: #3ca9e2;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
#engagement_details_page .accordion-flush .accordion-item .table-dark th {
  background-color: #000012;
}
#engagement_details_page .accordion-flush .accordion-item .table-bordered td,
.table-bordered th {
  border-color: #000012;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .table
  .btn-main {
  background-color: #000012;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .table
  tr
  td
  .avs_note_edit,
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .table
  tr
  td
  .avs_note_save,
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .table
  tr
  th
  .avs_note_edit,
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .table
  tr
  th
  .avs_note_save {
  color: #3ca9e2;
}
/* ==== */
#engagement_details_page .accordion-flush .accordion-item .accordion-body h1 {
  background-color: #000012;
  padding: 10px;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  h1
  button {
  color: #000012;
  background-color: #3ca9e2;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .form-control {
  color: #ffffff !important;
  background: none !important;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .checklist
  .table {
  color: #ffffff;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .checklist
  .table
  > :not(:last-child)
  > :last-child
  > * {
  border-color: #000012;
}
#engagement_details_page
  .accordion-flush
  .accordion-item
  .accordion-body
  .checklist
  .form-select:disabled {
  color: #ffffff !important;
  background-color: transparent !important;
}
/* ===== */
.eng_file_upload_modal .modal-content {
  color: #ffffff;
  background-color: #000012;
}
.eng_file_upload_modal .modal-content label {
  color: #ffffff;
}
.eng_file_upload_modal .modal-content .form-control {
  background: none !important;
  border-color: #3ca9e2;
}
.eng_file_upload_modal .modal-content .btn {
  color: #3ca9e2;
  background: none;
  border: 1px solid #3ca9e2;
}
/* PAGE:: end Welcome */

select option {
  background-color: #383838 !important;
  color: white !important;
  /* color: white; */
}
select option:disabled {
  color: #dad3d3 !important;
  background-color: #757272;
  /* font-weight: bold; */
}
::-webkit-file-upload-button {
  background: black;
  color: red;
  padding: 1em;
}
