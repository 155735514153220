textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
	box-shadow: inset 0 -1px 0 #ddd;
}

body {
	font-family: "Open Sans", sans-serif !important;
}

label {
	color: #fff;
}

aside {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 220px;
	height: 100%;
	background-color: #000000;
	// border-top: 5px solid #00a4ff;

	.sidebar-header {
		padding: 15px;
		text-align: center;
	}

	h5 {
		font-size: 20px;
		color: #e4ebf7;
		font-weight: 500;
		text-align: center;
		margin: 0;
		background-color: #000314;
		padding: 15px;
		// border-top: 1px solid #e4ebf7;
		//border-bottom: 1px solid #e4ebf7;
	}

	.sidebar-normal-menu {
		ul {
			li {
				&:last-child {
					a {
						border-bottom: 0;
					}
				}

				a {
					padding: 15px 18px;
					border-bottom: 1px solid #484848;
					display: block;

					.fa {
						font-size: 22px;
						vertical-align: bottom;
						color: #fff;
						margin-right: 19px;
					}

					span {
						font-size: 16px;
						color: #fff;
						font-weight: 600;
					}
				}
			}
		}
	}
}

#main_wraper {
	position: absolute;
	top: 0px;
	left: 221px;
	min-height: 100%;
	width: calc(100% - 221px);
	padding-bottom: 50px;
	// background-color: #000314;
	background-color: #383838;
}

.top-header {
	background-color: #f2f2f2;
	padding: 0px 0 0 16px;
	box-shadow: rgba(0, 0, 0, 0.1) -0.05px 1.95px 2.6px;
	// border-top: 5px solid #f7575d;

	.btn-neutral {
		background-color: transparent;
		color: #000314;
	}

	.av_holder {
		min-width: 140px;
		display: flex;
		background: #000;
		padding-left: 8px;
		padding-right: 8px;
		margin-left: 8px;
	}

	.btn-primary {
		background-color: #000314;
		color: #fff;
	}

	.input-group {
		.btn {
			margin: 0;
			border-radius: 0 20px 20px 0;
		}
	}

	.fa {
		font-size: 28px;
	}

	.btn-neutral {
		padding: 0 5px;
	}

	select {
		margin: 0 5px 0 0;
		min-width: 300px;
		border-radius: 5px;
		background-color: #383838;
		color: #e4ebf7;
	}

	.dropdown {
		button {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.dropdown-menu {
		left: -80px !important;

		&:before {
			display: none;
		}
	}
}

#deal_listing_page {
	padding: 28px;

	.clent-box-row {
		border-radius: 8px;
		overflow: hidden;

		.client-name {
			background-color: $custom-light-blue;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			font-weight: 600;
		}

		.client-name-text {
			background-color: $custom-dark-gray;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			// border-bottom: 1px solid #fff;
		}
	}

	button {
		&.btn-success {
			font-size: 16px;
		}
	}

	.card {
		box-shadow: none;

		.card-body {
			border-radius: 12px;
			overflow: hidden;
			box-shadow: none;
			border: 1px solid #98b1b9;

			.card-title {
				margin: 0;
				font-size: 14px;
				font-weight: 600;
				padding: 8px;
				// background-color: $custom-light-blue;
				color: #333;
			}

			.btn.btn-icon,
			.navbar .navbar-nav>a.btn.btn-icon {
				padding: 9px;
			}
		}
	}
}

#engage_list_page {
	padding: 28px;

	.clent-box-row {
		border-radius: 5px;
		overflow: hidden;

		.client-name {
			background-color: $custom-light-blue;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			font-weight: 600;
		}

		.client-name-text {
			background-color: $custom-dark-gray;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			// border-bottom: 1px solid #fff;
		}
	}

	.filter_form {
		.search {
			input {
				height: 36px;
				border-radius: 5px 0 0 5px;
			}

			button {
				height: 36px;
				margin: 0;
				padding: 8px 16px;
				border-radius: 0 5px 5px 0;
				background-color: #2c88d5;
			}
		}

		select {
			height: 36px;
			border-radius: 8px;
		}
	}

	button {
		&.btn-success {
			font-size: 16px;
		}
	}

	.card {
		box-shadow: none;

		.card-body {
			border-radius: 5px;
			overflow: hidden;
			box-shadow: none;
			border: 1px solid #98b1b9;

			.card-title {
				margin: 0;
				font-size: 18px;
				font-weight: 500;
				padding: 8px;
				// background-color: $custom-light-blue;
				color: #2c88d5;
			}

			.btn.btn-icon,
			.navbar .navbar-nav>a.btn.btn-icon {
				padding: 9px;
			}

			.table {
				thead {
					tr {
						th {
							border-bottom: 0;
						}
					}
				}

				.btn-main {
					background-color: #2e4f62;
					color: #ffffff;
					font-size: 14px;
					margin: 0;
				}

				tr {

					td,
					th {
						vertical-align: middle;
						position: relative;

						.avs_text {
							padding: 0 25px 0 0;
						}

						.avs_note_edit,
						.avs_note_save {
							position: absolute;
							top: 8px;
							right: 8px;
							background: #333;
							color: #fff;
							padding: 2px;
							border-radius: 65%;
							width: 24px;
							outline: none;
							margin: 0;
						}
					}
				}
			}
		}
	}
}

#dash_board_page {
	p.note {
		color: #2c88d5;
		font-size: 14px;
		font-weight: 500;
	}

	h3 {
		color: #2c88d5;
		font-size: 18px;
		font-weight: 500;
	}

	// .table-bordered td {
	//   border: 2px solid #000314;
	// }

	.table-bordered th {
		color: #2c88d5;
		border: 1px solid #ffffff;
	}

	// .table th {
	//   border-top: 0;
	//   background-color: #000314;
	// }

	padding: 28px;

	.fas {
		padding: 8px;
	}

	.card {
		box-shadow: none;
		background-color: #383838;

		.card-body {
			border-radius: 12px;
			overflow: hidden;
			box-shadow: none;
			border: 1px solid #98b1b9;

			.legendtoggle {
				fill: #fff !important;
			}

			.card-title {
				margin: 0;
				font-size: 14px;
				text-align: center;
				font-weight: 500;
				padding: 8px;
				//background-color: $custom-light-blue;
				color: #2c88d5;
			}

			.btn.btn-icon,
			.navbar .navbar-nav>a.btn.btn-icon {
				padding: 9px;
			}

			p {
				margin: 0;
			}

			.deal_update {
				h5 {
					color: #2196f3;
				}

				.fa-level-up-alt {
					color: #2196f3;
				}

				.fa-level-down-alt {
					color: #f00;
				}
			}
		}
	}
}

#add_deal_page {
	padding: 28px;

	p.note {
		color: #2c88d5;
		font-size: 14px;
		font-weight: 500;
	}

	h3 {
		color: #2c88d5;
		font-size: 18px;
		font-weight: 500;
	}

	button {
		font-weight: 700;
	}

	.card {
		box-shadow: none;

		.card-body {
			border-radius: 12px;
			overflow: hidden;
			box-shadow: none;
			border: 1px solid #98b1b9;

			.card-title {
				margin: 0;
				font-size: 18px;
				font-weight: 500;
				padding: 8px;
				// background-color: #eee;
				color: #2c88d5;
				// color: #fff;
			}

			.btn.btn-icon,
			.navbar .navbar-nav>a.btn.btn-icon {
				padding: 9px;
			}

			p {
				margin: 0;
			}

			.deal_update {
				h5 {
					color: #2196f3;
				}

				.fa-level-up-alt {
					color: #2196f3;
				}

				.fa-level-down-alt {
					color: #f00;
				}
			}

			select {
				border-radius: 8px;
			}

			input {
				&.form-control-plaintext {
					border: 0;
					font-size: 16px;
					padding: 8px;
					height: 36px;
				}

				&.form-control {
					border-radius: 8px;
					height: 36px;
				}
			}
		}
	}
}

#engagement_details_page {
	padding: 28px;

	.clent-box-row {
		//border-radius: 8px;
		overflow: hidden;

		.client-name {
			background-color: #2c88d5;
			// border: 1px solid #383838;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			font-weight: 600;
		}

		.client-name-text {
			// background-color: $custom-dark-gray;
			border: 1px solid #383838;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			// border-bottom: 1px solid #fff;

			a {
				color: #fff;
				position: absolute;
				top: 3px;
				right: 20px;
			}
		}
	}

	.card {
		box-shadow: none;

		.card-body {
			border-radius: 12px;
			overflow: hidden;
			box-shadow: none;
			border: 1px solid #98b1b9;

			.card-title {
				margin: 0;
				font-size: 18px;
				font-weight: 500;
				padding: 8px;
				background-color: $custom-light-blue;
				color: #fff;
			}

			.btn.btn-icon,
			.navbar .navbar-nav>a.btn.btn-icon {
				padding: 9px;
			}

			p {
				margin: 0;
			}
		}
	}

	.accordion-flush {
		margin-top: 16px;
		border: 1px solid #fff;

		.accordion-item {
			.accordion-body {
				h1 {
					background-color: #2e4f62;
					padding: 5px;
					color: #fff;
					text-transform: capitalize;
					font-weight: 700;
					font-size: 18px;

					button {
						float: right;
						border: 0;
						color: #2e4f62;
						background-color: #fff;
						font-size: 12px;
						padding: 6px 8px;
					}
				}

				.table {
					.btn-main {
						background-color: #2e4f62;
						color: #ffffff;
						font-size: 14px;
						margin: 0;
					}

					tr {

						td,
						th {
							vertical-align: middle;
							position: relative;

							.avs_text {
								padding: 0 25px 0 0;
							}

							.avs_note_edit,
							.avs_note_save {
								position: absolute;
								top: 8px;
								right: 8px;
								background: #333;
								color: #fff;
								padding: 2px;
								border-radius: 65%;
								width: 24px;
								outline: none;
								margin: 0;
							}
						}
					}

					&.table-striped {
						tr {
							td {
								color: #fff;
							}

							&:nth-child(even) {

								td,
								th {
									background-color: #525252;
									color: #fff;
								}
							}
						}
					}
				}
			}

			.accordion-button {
				// padding: 0.4rem 0.45rem;
				// background-color: #2e4f62;
				// border-radius: 4px;
				outline: 0;
				color: #2c88d5;
				background: transparent;
				border-bottom: 1px solid #fff;

				&::after {
					//background-color: #fff;
					background-color: transparent;
					// border-radius: 4px;
				}
			}
		}
	}
}

#deal_engagement_details_page {
	padding: 28px;

	.deal-right-text {
		color: #ccc;
	}

	.accordion-item {
		background: none;
		border: 1px solid #fff;
	}

	#child_accordion {
		.accordion-flush {
			margin-top: 16px;

			.accordion-item {
				.accordion-body {
					h1 {
						// background-color: #2e4f62;
						padding: 5px;
						color: #fff;
						text-transform: capitalize;
						font-weight: 700;
						font-size: 18px;

						button {
							float: right;
							border: 0;
							color: #2e4f62;
							// background-color: #fff;
							font-size: 12px;
							padding: 6px 8px;
						}
					}

					.table {
						.btn-main {
							// background-color: #2e4f62;
							color: #ffffff;
							font-size: 14px;
							margin: 0;
						}

						tr {
							// th {
							//   color: #2c88d5;
							// }

							td,
							th {
								vertical-align: middle;
								position: relative;

								.avs_text {
									padding: 0 25px 0 0;
								}

								.avs_note_edit,
								.avs_note_save {
									position: absolute;
									top: 8px;
									right: 8px;
									background: #333;
									color: #fff;
									padding: 2px;
									border-radius: 65%;
									width: 24px;
									outline: none;
									margin: 0;
								}
							}
						}

						&.table-striped {
							tr {
								td {
									color: #fff;
								}

								&:nth-child(even) {

									td,
									th {
										background-color: #4c4a4a;
										color: #fff;
									}
								}
							}
						}
					}
				}

				.accordion-button {
					padding: 0.4rem 0.45rem;
					background-color: rgba(0, 0, 0, 0);
					border-radius: 0px;
					outline: 0;
					color: #2c88d5;

					&::after {
						//  background-color: #fff;
						border-radius: 4px;
					}
				}
			}
		}

		.form_holder {
			.addAtt {
				padding: 0 8px;
			}

			padding: 8px;

			label {
				margin-bottom: 2px;
			}

			.form-select,
			input.form-control {
				padding: 3px 8px;
				font-size: 14px;
				height: 30px;
			}

			.btn-primary {
				background-color: #2e4f62;
			}
		}
	}

	.avs-setup {
		background-color: #2c88d5;
		padding: 5px;
		color: #fff;
		text-transform: capitalize;
		font-weight: 500;

		button {
			border: 0;
			color: #2e4f62;
			background-color: #fff;
			font-size: 12px;
			padding: 4px 8px;
		}
	}

	.table {
		tr {

			td,
			th {
				padding: 8px 16px;
			}
		}
	}

	#flush-collapseThree {
		.accordion-body {
			padding: 8px;
		}

		.tab-content {
			border: 1px solid #2e4f62;
		}
	}

	.clent-box-row {
		border-radius: 0px;
		overflow: hidden;

		.client-name {
			background-color: #2c88d5;
			padding: 14px;
			font-size: 14px;
			color: #fff;
			font-weight: 600;
			border: 1px solid #2c88d5;
		}

		.client-name-text {
			// background-color: #f2f2f2;
			padding: 14px;
			font-size: 14px;
			color: #fff;
			border: 1px solid #fff;
			border-left: 0;
			height: 50px;

			a {
				color: #fff;
				position: absolute;
				top: 3px;
				right: 20px;
			}
		}
	}

	.card {
		box-shadow: none;

		.card-body {
			border-radius: 5px;
			overflow: hidden;
			box-shadow: none;
			border: 1px solid #98b1b9;

			.card-title {
				margin: 0;
				font-size: 18px;
				font-weight: 500;
				padding: 8px;
				// background-color: #000;
				color: #2c88d5;
				border-bottom: 1px solid #fff;
			}

			.btn.btn-icon,
			.navbar .navbar-nav>a.btn.btn-icon {
				padding: 9px;
			}

			.color-red {
				color: #ee3139;
				font-size: 18px;
			}

			.parameter-row p {
				margin-bottom: 0;
				font-weight: 500;
				color: #0d87ac;
			}

			p {
				margin: 0;
			}

			.form-switch {
				.left {
					margin: 0 30px 0 0;
				}

				.right {
					margin: 0 0 0 30px;
				}

				.form-check-input {
					width: 40px;
					margin: 0;
					height: 20px;
					position: relative;
					float: none;
				}
			}
		}
	}

	.accordion-flush {
		margin-top: 16px;

		.accordion-item {
			.accordion-body {
				position: relative;

				.deal-left-title {
					font-weight: 600;
					color: #0d87ac;

					small {
						font-size: 0.875em;
					}
				}

				h1 {
					background-color: #2e4f62;
					padding: 5px;
					color: #fff;
					text-transform: capitalize;
					font-weight: 700;
					font-size: 18px;

					button {
						float: right;
						border: 0;
						color: #2e4f62;
						background-color: #fff;
						font-size: 12px;
						padding: 6px 8px;
					}
				}

				.table {
					.btn-main {
						background-color: #2e4f62;
						color: #ffffff;
						font-size: 14px;
						margin: 0;
					}

					tr {

						td,
						th {
							vertical-align: middle;
							position: relative;

							.avs_text {
								padding: 0 25px 0 0;
							}

							.avs_note_edit,
							.avs_note_save {
								position: absolute;
								top: 8px;
								right: 8px;
								background: #333;
								color: #fff;
								padding: 2px;
								border-radius: 65%;
								width: 24px;
								outline: none;
								margin: 0;
							}
						}
					}

					&.table-striped {
						tr {
							td {
								color: #fff;
							}

							&:nth-child(even) {

								td,
								th {
									background-color: #525252;
									color: #fff;
								}
							}
						}
					}
				}
			}

			.accordion-button {
				//padding: 1rem 1.25rem;
				background-color: rgba(0, 0, 0, 0);
				// border-radius: 4px;
				outline: 0;
				color: #2c88d5;
				border-bottom: 1px solid #fff;

				&::after {
					background-color: #fff;
					border-radius: 4px;
				}
			}

			.deal-edit {
				position: absolute;
				top: 16px;
				right: 16px;

				background: #1e97bd;
				color: #fff;
				padding: 4px 2px;
				border-radius: 4px;
				width: 24px;
				outline: none;
				margin: 0;
				z-index: 99;
			}

			.deal-update {
				position: absolute;
				top: 16px;
				right: 16px;
			}
		}
	}
}

#periodsTableBody {
	.btn {
		padding: 2px 8px;
		white-space: nowrap;
		margin: 0;
	}
}

table {
	&#dealDetailsTableInDashboard {
		tr {

			th,
			td {
				white-space: nowrap;
			}
		}
	}
}

#covenantTable {
	table {
		tr {

			th,
			td {
				white-space: nowrap;
			}
		}
	}
}

.componant-box-title {
	display: inline-block;
	background-color: #2c88d5;
	padding: 5px 11px;
	color: #fff;
	margin: 0 0 0 10px;
}

.componant-box-content {
	// background-color: rgb(29, 151, 188);
	border: 1px solid #2c88d5;
	padding: 7px 16px;

	.box {
		background-color: transparent;
		border: 1px solid #2c88d5;
		padding: 15px;
		cursor: move;
		text-align: center;
		border-radius: 4px;
		opacity: 1;
	}
}

.drag-area-sec {
	border: 2px dotted #2c88d5;
	border-radius: 4px;
	padding: 40px 0;
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	min-height: 100px;

	.boxitem {
		border-radius: 4px;
		border: 1px solid #2c88d5;
		padding: 15px 20px;
		color: #fff;
		height: 60px;
		display: grid;
		align-items: center;
		position: relative;
	}
}


.modal label {
	color: #333;
	margin-bottom: 0;
}


.create_group {
	margin: 8px 0 !important;
	padding: 5px 8px;
	margin: 0;
	color: #fff !important;
	font-size: 12px;
	background-color: #1e97bd;
}

fieldset {
	border: 1px solid #2c88d5;
	border-radius: 8px;
	padding: 30px 16px;
	position: relative;
	margin-top: 24px;

	legend {
		position: absolute;
		top: -16px;
		background-color: #2c88d5;
		display: inline;
		font-size: 18px;
		color: #fff;
		padding: 3px 18px;
		width: auto;
		border-radius: 24px;

		.btn {
			padding: 0;
			margin: 0;
			color: #fff !important;
			font-size: 12px;
		}
	}
}