.my-breadcrumb-container {
  text-align: right;
  margin: 0 !important;
  vertical-align: middle;
}
.breadcrumb {
  //   padding: 5px !important;
  padding-left: 0;
  background-color: transparent;
  margin: 0;
}
.breadcrumb-item.active {
  color: #fff;
}
