fieldset legend {
  border-radius: 4px;
  padding: 3px 9px;
}
textarea {
  padding: 3px 8px !important;
  font-size: 14px !important;
  // height: 30px !important;
  min-height: 50px !important;
}
